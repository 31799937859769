import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout";
import store from '../store'

Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "login",
        component: () =>
            import ("@/views/login/index.vue"),
        meta: {
            title: "登录",
            text: "",
            nav: true,
            keepAlive: true,
            requiresAuth: false
        },
    },
    {
        path: "/password",
        name: "password",
        component: () =>
            import ("@/views/password/index.vue"),
        meta: {
            title: "忘记密码",
            text: "",
            nav: true,
            keepAlive: true,
            requiresAuth: false
        },
    },
];


export const DynamicRoutesz = [{
    path: "/",
    component: Layout,
    redirect: "home",
    children: [{
            path: "management_edit",
            name: "management_edit",
            component: () =>
                import ("@/views/management/edit"),
            meta: {
                title: "企业管理编辑",
                text: true,
                nav: true,
                keepAlive: true,
                requiresAuth: true
            },
        },
        {
            path: "usermanagement_edit",
            name: "usermanagement_edit",
            component: () =>
                import ("@/views/usermanagement/edit"),
            meta: {
                title: "用户管理编辑",
                text: true,
                nav: true,
                keepAlive: true,
                requiresAuth: true
            },
        },
        {
            path: "distribution_edit",
            name: "distribution_edit",
            component: () =>
                import ("@/views/soc/distribution/edit"),
            meta: {
                title: "经销/代理管理",
                text: true,
                nav: true,
                keepAlive: true,
                requiresAuth: true
            },
        },
        {
            path: "manual",
            name: "manual_document",
            component: () =>
                import ("@/components/manual"),
            meta: {
                title: "帮助文档",
                text: true,
                nav: false,
                keepAlive: true,
                requiresAuth: true
            },
        },
    ]
}]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const taskmanuserToken = localStorage.getItem('taskmanuserToken');
    if (taskmanuserToken) {
        if (!store.state.userPermission.permissionLists) {
            store.dispatch('userPermission/FETCHUSERINFO').then(() => {
                next({...to, replace: true })
            })
        } else {
            next()
        }
    } else {
        if (to.matched.length > 0 && !to.matched.some(record => record.meta.requiresAuth)) {
            next()
        } else {
            next('/login')
        }
    }
})
export default router;