<template>
  <div class="header-sider">
    <section class="box-sider">
      <div class="logo_home2"></div>
      <span>高指-智能工厂管理系统</span>
      <span class="banben">v1.3</span>
    </section>
    <div><img src="@/assets/images/common/logo.png" class="logo_home"
        @click="() => { info_all(); dialogVisible = true; }" /></div>
    <el-dialog top="10vh" width="35vh" class="dialog_info" title="联系我们" :modal="false" :visible.sync="dialogVisible"
      :before-close="handleClose">
      <div style="margin-bottom: 1em;">
        <i style="color: #999999; font-size: 24px; line-height: 1.5em; vertical-align: middle;"
          class="iconfont icon-dianhua"></i>
        <span style="margin-left: 1em; display:inline-block;">020-36398816</span>
      </div>
      <div style="margin-bottom: 1em;">
        <i style="color: #999999; font-size: 24px; line-height: 1.5em; vertical-align: middle; "
          class="iconfont icon-youxiang-32"></i>
        <span style="margin-left: 1em;">ligong@li-gong.com</span>
      </div>
    </el-dialog>
    <section class="user-info">
      <!-- 帮助 -->
      <!-- <div class="pointer" @click="goMannualRouter">
        <img src="@/assets/images/common/icon_help.png" />
      </div> -->
      <div>
        <img src="@/assets/images/common/icon_help.png" class="pointer" @click="goMannualRouter" />
        <img @click="visible = true" class="userPic" src="@/assets/images/common/logo-ligong.svg" />
        <span @click="visible = true">{{ userName }}</span>
        <span class="timeb">{{ timeb }}</span>
        <span class="times">{{ times }}</span>
        <el-popover placement="left" width="160" offset=60 v-model="visible">
          <el-button class="logoutButton" @click="logoutHandle">退出登录</el-button>
          <el-button class="closeButton" @click="visible = false">取消</el-button>
        </el-popover>
      </div>
    </section>

  </div>
</template>

<script>
import '../../assets/fonts/iconfont.css'
// import { getfacturerDetail } from '@/api/soc.js'
import dayjs from 'dayjs'
import { userLogout } from '@/api'
import { mapState, mapActions } from 'vuex'
export default {
  name: "HeaderBan",
  data() {
    return {
      timeb: dayjs(new Date()).format("YYYY.MM.DD"),
      times: null,
      visible: false,
      dialogVisible: false
    };
  },
  computed: {
    ...mapState('userPermission', ['userName'])
  },
  created() {
  },
  mounted() {
    this.getCurDate()
  },
  destroyed() {
    clearInterval(this.times)
  },
  methods: {
    ...mapActions('userPermission', ['EXITFUC']),
    getCurDate() {
      setInterval(() => {
        this.times = dayjs(new Date()).format("HH:mm");
      }, 1000)
    },
    async logoutHandle() {
      await userLogout().then((res) => {
        if (res.code === '200') {
          this.EXITFUC()
          this.$router.push("/login");
        }
      })
    },
    handleClose(done) {
      done();
    },
    info_all() {
      // getfacturerDetail().then(res=>{
      //   console.log(84,res)
      //   this.form = res.data
      // })
    },
     goMannualRouter() {
      this.$router.push({
        path: 'manual'
        // query:row
      })
    }

  }
};
</script>

<style lang="scss" >
.header-sider {
  color: #b9b9b9;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    vertical-align: middle;
  }

  .user-info {
    padding: 0px 20px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 18px;

    .userPic {
      width: 32px;
      height: 32px;
      margin: 0 10px;
    }

    .timeb {
      margin: 0 10px 0 30px;
    }

    .times {
      font-size: 30px;
      color: #fff;
    }
    img {
      cursor: pointer;
      margin:0px 8px;
      vertical-align: middle
    }
  }

  .header-menu {
    color: #fff !important;
    padding: 10px 20px 0 20px;

    h3 {
      font-size: 24px;
      line-height: 36px;
      margin-right: 30px;
      color: #e8e8e8;
    }

    .menu-info {
      display: flex;

      p {
        margin: -12px 0 0 0;
      }
    }
  }

  .box-sider {
    padding: 0 10px;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: baseline;
    height: 64px;
    padding-top: 17px;
    margin: 0 20px;

    .banben {
      margin-left: 19px;
      width: 36px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #2DEB79;
      letter-spacing: 1px;
    }

    h2 {
      font-size: 30px;
      height: 60px;
      color: #2deb79;
      margin-right: 6px;
    }

    span {
      font-size: 18px;
      line-height: 50px;
      white-space: nowrap !important;
    }

    .logo_home2 {
      width: 165px;
      height: 29px;
      background: url("../../assets/images/common/logo2.png") no-repeat center center;
      background-size: contain;
      margin-right: 8px;
    }
  }

  .logo_home {
    width: 113px;
    height: 38px;
  }

  .el-dialog {
    background-color: #262626;
    border-radius: 1em;
    width: 354px;
  }

  .dialog_info {
    text-align: left;
    font-size: 20px;

    .el-dialog__body {
      padding: 0.5vw 1vw;
    }
  }
}

.text1 {
  color: #fff;
}
.cursor {
      cursor: pointer;
      border: 1px solid #f00;
      margin: 0 8px;
    }

</style>
