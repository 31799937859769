import { MessageBox } from "element-ui";

//删除二次确认弹窗
export const isComfirm = (data, callback) => {
    MessageBox.confirm('确认后，内容将无法找回', '确认删除这行内容?', {
        customClass: 'dataM',
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        callback(data)
    }).catch(() => {

    })
}

export function recurRouter(getRouter = [], allRoutes = []) {
    let resultRoutes = [];
    allRoutes.forEach((i) => {
        getRouter.forEach((item) => {
            if (item.name === i.meta.title) {
                if (item.children && item.children.length > 0) {
                    i.children = recurRouter(item.children, i.children)
                }
                resultRoutes.push(i)
            }
        })
    })
    return resultRoutes;
}