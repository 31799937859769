const isProduction = process.env.NODE_ENV === 'production';
const baseport = window.location.port
const baseHostName = window.location.host
const baseHost = baseport ? `http://${baseHostName}/api` : `${window.location.href}/api`;
const baseHost2 = baseport ? `/api` : `/api`; //${window.location.hostname}
const defaultUrl = 'http://10.0.30.244:16666/api'
export const urls = {
    baseUrls: isProduction ? baseHost : defaultUrl,
    baseUrls2: isProduction ? baseHost2 : defaultUrl
};

console.log('baseUrls:', urls.baseUrls);
console.log('baseUrls2:', urls.baseUrls2);


//生产：https://taskman.li-gong.com/api