<template>
  <div class="nav-ban">
    <el-breadcrumb separator="/" >
      <el-breadcrumb-item :to="{ path: 'home' }">{{homeName}}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="$route.meta.fatTitle">{{$route.meta.fatTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <h2>{{$route.meta.title}}</h2>
  </div>
</template>

<script>
export default {
  name: "navBread",
  data() {
    return {
      homeName: '主页',
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-ban{
  background: #232323;
  border: 2px solid #161616;
  border-radius: 2px;
  text-indent: 24px;
  color: #B9B9B9 !important;
  padding: 14px 0 0 16px;
  h2{
    color: #E8E8E8;
    font-size:24px;
    line-height: 60px;
    font-weight: normal;
    text-align: left;
    text-indent: 0;

  }
      a{
        color: #B9B9B9 !important;
      }
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner{
  color: #B9B9B9 !important;
}
::v-deep .el-breadcrumb__inner.is-link{
  color: #B9B9B9 !important;
}
::v-deep .el-breadcrumb__item{
  text-indent: 0;
}
</style>
