import { userInfos, customerModuleList } from '../../api'
import router, { DynamicRoutesz } from "../../router"
import { manufacturerManager, manufacturerUser, distributorManager, distributorUser, customerManager, customerUser } from '../../router/users'
import { recurRouter } from '../../utils/utils'
import allroutes from '../../router/aroutes'
export default {
    namespaced: true,
    state: {
        permissionLists: null,
        menuLists: [],
        menuCur: window.location.pathname.slice(1),
        userName: '',
    },
    getters: {

    },
    mutations: {
        SET_USERNAME(state, data) {
            state.userName = data;
        },
        CLEAR_USERNAME(state) {
            state.userName = ''
        },
        SET_MENUCUR(state, data) {
            state.menuCur = data;
        },
        CLEAR_MENUCUR(state) {
            state.menuCur = ''
        },
        SET_MENU(state, menu) {
            state.menuLists = menu;
        },
        CLEAR_MENU(state) {
            state.menuLists = []
        },
        SET_CUSTMODULE(state, data) {
            state.customerModule = data;
        },
        CLEAR_CUSTMODULE(state) {
            state.customerModule = ''
        },
        SET_PERMISSION(state, data) {
            state.permissionLists = data;
        },
        CLEAR_PERMISSION(state) {
            state.permissionLists = ""
        },

    },
    actions: {
        async FETCHUSERINFO({ commit }) {
            const userInfo = await userInfos();
            let tempData = null;
            let customerLists = null;
            commit('SET_USERNAME', userInfo.data.name);
            switch (userInfo.data.roles[0]) {
                case 'manufacturer_manager':
                    tempData = manufacturerManager;
                    break;
                case 'manufacturer_user':
                    tempData = manufacturerUser;
                    break;
                case 'distributor_manager':
                    tempData = distributorManager;
                    break;
                case 'distributor_user':
                    tempData = distributorUser;
                    break;
                case 'customer_manager':
                    customerLists = await customerModuleList();
                    tempData = customerManager;

                    break;
                case 'customer_user':
                    customerLists = await customerModuleList();
                    tempData = customerUser;
                    break;
                default:
                    break;
            }
            if (customerLists && customerLists.data) {
                commit('SET_CUSTMODULE', customerLists.data);

            }
            let tempData2 = recurRouter(tempData, allroutes)
            let containerRoutes = DynamicRoutesz.find(i => i.path === "/");
            let children = containerRoutes.children

            children.push(...tempData2)
            commit('SET_MENU', tempData2)
            children.forEach(((i, index) => {
                if (i.children) {
                    i.children.forEach((j => {
                        children.push(j)
                    }))
                    children.splice(index, 1);
                }
            }))


            let initialRoutes = router.options.routes
            for (let i = 0; i < DynamicRoutesz.length; i++) {
                router.addRoute(DynamicRoutesz[i])
            }
            commit('SET_PERMISSION', [...initialRoutes, ...DynamicRoutesz]);
            children.splice(2, (children.length - 3));
        },
        EXITFUC({ commit }) {
            localStorage.removeItem('taskmanuserToken');
            commit('CLEAR_PERMISSION')
            commit('CLEAR_MENU')
            commit('SET_MENUCUR', 'home')
        }
    },
    modules: {

    }
}