const allroutes = [{
        path: "home",
        name: "Home",
        component: () =>
            import ("@/views/home"),
        meta: {
            title: "主页",
            text: "",
            nav: false,
            keepAlive: true,
            iconUrl: require('@/assets/images/common/home.png'),
        },
    },
    {
        path: "usercenter",
        name: "usercenter",
        component: () =>
            import ("@/views/usercenter"),
        meta: {
            title: "个人中心",
            text: "",
            nav: true,
            keepAlive: true,
            iconUrl: require('@/assets/images/common/user.png'),

        },
    },
    {
        path: "management",
        name: "management",
        component: () =>
            import ("@/views/management"),
        meta: {
            title: "企业管理",
            text: "",
            nav: true,
            keepAlive: true,
            iconUrl: require('@/assets/images/common/management.png'),

        },
    },

    {
        path: "usermanagement",
        name: "usermanagement",
        component: () =>
            import ("@/views/usermanagement"),
        meta: {
            title: "用户管理",
            text: "",
            nav: true,
            keepAlive: true,
            iconUrl: require('@/assets/images/common/users.png'),
            requiresAuth: true
        },
    },
    {
        path: "soc",
        name: "",
        component: () =>
            import ("@/views/soc"),
        meta: {
            title: "运维管理",
            text: "",
            nav: true,
            keepAlive: true,
            iconUrl: require('@/assets/images/common/soc.png'),
            requiresAuth: true
        },
        children: [{
                path: "manufacturer",
                name: "manufacturer",
                component: () =>
                    import ("@/views/soc/manufacturer"),
                meta: {
                    title: "厂家信息",
                    text: "",
                    nav: true,
                    keepAlive: true,
                    requiresAuth: true
                },
            },
            {
                path: "distribution",
                name: "distribution",
                component: () =>
                    import ("@/views/soc/distribution"),
                meta: {
                    title: "经销/代理管理",
                    text: "",
                    nav: true,
                    keepAlive: true,
                    requiresAuth: true
                },
            },
        ]
    },

    {
        path: "clientManagement",
        name: "clientManagement",
        component: () =>
            import ("@/views/clientManagement"),
        meta: {
            title: "客户端管理",
            text: "",
            nav: true,
            keepAlive: true,
            iconUrl: require('@/assets/images/common/client.png'),
            requiresAuth: true
        },
    },

];

export default allroutes;