// 厂家管理员
export const manufacturerManager = [{
            name: "主页",
        }, {
            name: "个人中心",
        },
        {
            name: "企业管理",
        },
        {
            name: "运维管理",
            children: [
                { name: "厂家信息", },
                { name: "经销/代理管理", }
            ]
        },
        {
            name: "客户端管理",
        },
    ]
    // 厂家用户
export const manufacturerUser = [{
            name: "主页",
        }, {
            name: "个人中心",
        },
        {
            name: "企业管理",
        },
        {
            name: "运维管理",
            children: [
                { name: "厂家信息", },
                { name: "经销/代理管理", }
            ]
        },
        {
            name: "客户端管理",
        },
    ]
    //  经销商管理员
export const distributorManager = [{
        name: "主页",
    }, {
        name: "个人中心",
    }, ]
    // 经销商用户
export const distributorUser = [{
        name: "主页",
    }, {
        name: "个人中心",
    }, ]
    // 客户管理员
export const customerManager = [{
            name: "主页",
        }, {
            name: "个人中心",
        },
        {
            name: "用户管理",
        },
    ]
    // 客户用户
export const customerUser = [{
    name: "主页",
}, {
    name: "个人中心",
}, ]