import {get, post } from "../utils/http"

//登录模块
//获取钉钉登录参数
export function getDingTalk() {
    return get(`/sso/param/dingtalk`)
}

// 通过钉钉授权码生成临时票据
export function getTmepDingTalk(param) {
    return post(`/sso/login/dingtalk`, param)
}

// 使用Ticket获取用户信息
export function getUserInfo(params) {
    return post(`/sso/login/ticket`, params)
}

// 绑定第三方账号
export function bindAccount(params) {
    return post(`/sso/social-account/pre-bind`, params)
}

//单点登录
export function userLogin(param) {
    return post(`/sso/login`, param)
}

// 忘记密码
export function forgetPassword(params) {
    return post(`/user/change-pwd/by-forget`, params)
}

// 忘记密码--验证码
export function codeChangePassword(params) {
    return post(`/captcha/change-pwd/by-forget/send`, params)
}

//单点退出
export function userLogout() {
    return post(`/sso/logout`)
}

//获取本人信息
export function userInfos() {
    return get(`/user/info`)
}

// 本人获取列表
export function customerModuleList() {
    return get(`/customer/user/module/list`)
}

// 获取艾利特APS链接
export function apsAddress() {
    return get(`/temporary/elibot/aps/address`)
}

// 获取WMS链接
export function wmsAddress() {
    return get(`/temporary/ligong/wms/address`)
}