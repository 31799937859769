<template>
  <div>
    <el-menu
      :default-active="menuCurrent"
      class="menu-aps"
      background-color="#232323"
      text-color="#fff"
      router
    >
      <template v-for="item in menuLists">
        <el-submenu
          index="1"
          v-if="item.children && item.children.length > 0"
          :key="item.meta.title"
        >
          <template slot="title">
            <img :src="item.meta.iconUrl" />
            <span>{{ item.meta.title }}</span>
          </template>
          <el-menu-item  @click="menuHandle(w)"
            v-for="w in item.children"
            :index="w.path"
            :key="w.meta.title"
            >{{ w.meta.title }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item v-else :index="item.path" :key="item.index" @click="menuHandle(item)">
          <img :src="item.meta.iconUrl" />
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </template>
     
    </el-menu> 
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
export default {
  name: "SideLeft",
  data() {
    return {
     
    };
  },
  computed: {
    ...mapState('userPermission', ['menuLists', 'menuCur']),
    menuCurrent() {
      let menuCurTemp = '';
      switch (this.menuCur) {
        case 'usermanagement_edit':
          menuCurTemp = 'usermanagement';
          break;
        case 'management_edit':
          menuCurTemp = 'management';
          break;
        case 'distribution_edit':
          menuCurTemp = 'distribution';
          break;
        case 'login':
          menuCurTemp = 'home';
          break;
        default:
          menuCurTemp = this.menuCur;
          break;
      }
      return menuCurTemp
    }
  },
  mounted() {

  },
  methods:{
  ...mapMutations('userPermission', ['SET_MENUCUR']),
    menuHandle(data) {
      this.SET_MENUCUR(data.path)
    }
  }

};
</script>

<style lang="scss" scoped>
.menu-aps {
  color: #fff;
  font-size: 44px !important;
  background: #232323;
  text-align: left;
  height: 94vh;
  width: 240px;
}
.menu-aps a {
  color: #fff;
}
.menu-aps img {
  width: 34px;
  height: 34px;
  margin-right: 8px;
}
::v-deep.el-menu {
  background-color: transparent;
  border-right: none;

  .el-menu-item {
    color: #ffffff;
    height: 50px;
    line-height: 50px;
    font-size: 18px !important;
    i {
      color: #ffffff;
    }
  }
  .el-menu-item:hover,
  .el-menu-item:focus {
    background-color: transparent;
    background-position: center;
    border-right: 4px solid #2deb79;
    background: linear-gradient(
      270deg,
      rgba(0, 255, 112, 0.55) 0%,
      rgba(94, 223, 150, 0.14) 100%
    ) !important;
  }
    .el-menu-item.is-active > .el-submenu__title {
    color: #ffffff;
    border-right: 4px solid #2deb79;
    background: linear-gradient(
      270deg,
      rgba(0, 255, 112, 0.55) 0%,
      rgba(0, 255, 112, 0.14) 100%
    ) !important;
  }
  .el-menu-item.is-active {
    color: #ffffff;
    border-right: 4px solid #2deb79;
    background: linear-gradient(
      270deg,
      rgba(0, 255, 112, 0.55) 0%,
      rgba(0, 255, 112, 0.14) 100%
    ) !important;
  }
  .el-submenu__title {
    margin-left: -4px;
  }
  .el-submenu__title i {
    color: #ffffff;
  }
  .el-menu-item-group {
    .el-menu-item-group__title {
      color: #ffffff;
    }
  }
  .el-submenu {
    .el-submenu__title {
      text-indent: 6px;
      font-size: 18px;
      height: 60px;
      line-height: 60px;
      &:hover {
        background-color: transparent;
        background-position: center;
        border-right: 4px solid #2deb79;
        background: linear-gradient(
          270deg,
          rgba(0, 255, 112, 0.55) 0%,
          rgba(0, 255, 112, 0.14) 100%
        ) !important;
      }
    }
    .el-menu-item {
      min-width: 180px;
      text-indent: 14px;
    }
  }
}
</style>
