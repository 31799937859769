import axios from "axios";
import { Message, Loading } from "element-ui";
import store from "../store";
import router from "../router"
import { urls } from '@/utils/urls'

let loading = null;
const requestTime = 6000;
let baseAPI = urls.baseUrls2;
const instanceAxios = axios.create({
    baseURL: `${baseAPI}`,
    timeout: 60000,
});

/*
 * 请求拦截
 */
instanceAxios.interceptors.request.use(
    (config) => {
        loading = Loading.service({
            lock: true,
            text: "请求中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        const taskmanuserToken = localStorage.getItem('taskmanuserToken')
        if (config.url !== '/sso/login') {
            config.headers.Authorization = `Bearer ${taskmanuserToken}`
        }
        console.log('config:', config)
        return config;
    },
    (error) => {
        Message({
            message: error.message,
            type: "error",
            duration: requestTime,
        });
        return Promise.reject(error);
    }
);

/*
 * 响应拦截
 */
instanceAxios.interceptors.response.use(
    (res) => {
        if (loading != null) {
            loading.close();
        }
        return res.data;
    },
    (error) => {
        if (loading != null) {
            loading.close();
        }
        responseError(error);
        return Promise.reject(error);
    }
);

function responseError(error) {
    if (error && error.response) {
        switch (error.response.status) {
            case 201:
                Message({
                    message: `请求错误 201, 错误信息 ${error.message}`,
                    type: "error",
                    duration: requestTime,
                });
                break;
            case 401:
                Message({
                    message: `请求错误 401, 系统登录超时，请重新登录`,
                    type: "error",
                    duration: requestTime,
                });
                store.dispatch('userPermission/EXITFUC')
                router.push("/login");
                break;
            case 403:
                Message({
                    message: `请求错误 403, 错误信息 ${error.message}`,
                    type: "error",
                    duration: requestTime,
                });
                break;
            case 404:
                Message({
                    message: `请求错误 404, 错误信息 ${error.message}`,
                    type: "error",
                    duration: requestTime,
                });
                break;
            default:
                Message({
                    message: error.message,
                    type: "error",
                    duration: requestTime,
                    showClose: true,
                });
                break;
        }
    }
}

//get方法
export function get(url, params) {
    return instanceAxios.get(url, { params });
}

//get方法 url方式
export function getParams(url) {
    return instanceAxios.get(`${url}`);
}

//post方法
export function post(url, params) {
    return instanceAxios.post(url, params);
}

//post方法 url方式
export function postParams(url) {
    return instanceAxios.post(url);
}

//del方法
export function del(url, params) {
    const config = {
        method: "delete",
        url: url,
    };
    if (params) config.params = params;
    return instanceAxios.delete(url, config);
}

// url方式单个参数 delete
export function delSingleParam(url, params) {
    return instanceAxios.delete(`${url}${params}`);
}

// params方式 delete
export function delParams(url, params) {
    const config = {
        method: "delete",
        url: url,
    };
    if (params) config.data = params;
    return instanceAxios.delete(url, config);
}

//put方法
export function put(url, data) {
    return instanceAxios.put(url, data);
}

export default instanceAxios;