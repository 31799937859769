<template>
  <div class="side_content">
    <navBread v-if="$route.meta.nav"></navBread>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import navBread from '@/components/NavBread.vue'
export default {
  name: "SideRight",
  data() {
    return {};
  },
  components:{
    navBread,
  }
};
</script>

<style lang="scss" scoped>
.side_content{
  font-size: 16px !important;
}

</style>
